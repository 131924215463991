@import url("https://use.typekit.net/vys7ivk.css");

/* hack. see https://github.com/prc5/react-zoom-pan-pinch/issues/135#issuecomment-683463453 */
.hoverImage {
  pointer-events: auto !important;
}

.initialModal {
  background-color: black;
  color: white;
}

@keyframes grow {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes dropDown600 {
  0% {
    transform: translateY(-600px);
  }
  50% {
    transform: translateY(-600px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes dropDown300 {
  0% {
    transform: translateY(-300px);
  }
  50% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
